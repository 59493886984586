<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <!-- <avue-data-icons :option="option"></avue-data-icons> -->
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template slot="searchMenu" slot-scope="{ row, size }">
        <el-button icon="el-icon-download" :size="size" @click="searchExcel(row)"
        >导出</el-button>
      </template>
      <template slot="member_nameSearch">
        <div style="height: 32px;width: 100%">
          <avue-form ref="form" :option="memberSearchOp" v-model="memberSearchFo" ></avue-form>
        </div>
      </template>
    </avue-crud>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
