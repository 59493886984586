import './list.scss';
import dataApi from '@/api/member/energy.js';
import member from '@/api/member/member';
/*
 *能量值日志
 * */
export default {
  name: 'member-energy',
  components: {},
  data() {
    return {
      option: {
        data: [
          {
            title: '总能量值',
            count: 12678
          },
          {
            title: '今日充值',
            count: 22139
          },
          {
            title: '今日扣减',
            count: 35623
          },
          {
            title: '今日评论',
            count: 16826
          },
          {
            title: '今日新增有效价值',
            count: 16826
          },
          {
            title: '今日扣减有效价值',
            width: '200',
            count: 16826
          }
        ]
      },
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      memberSearchOp: {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            labelWidth: 0,
            label: '',
            prop: 'table',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '会员名称',
                  display: true,
                  prop: 'name',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  type: 'input',
                  label: '手机号',
                  display: true,
                  prop: 'mobile',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  label: '能量值',
                  prop: 'energy'
                },
                {
                  label: '赠送能量值',
                  prop: 'giving_energy'
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              return `${row.name}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              // 请求列表数据
              member.getList.r({ ...data, page: page.currentPage, pageSize: page.pageSize }).then((res) => {
                // 渲染数据
                callback({
                  total: res.data.total,
                  data: [...res.data.list]
                });
              });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          }
        ]
      },
      memberSearchFo: {},
      tableData: [],
      tbleOption: {
        ...this.$store.getters.tableConfig,
        total: 20,
        align: 'center',
        cellBtn: false,
        menu: false,
        addBtn: false,
        cancelBtn: false,
        filterBtn: false,
        selection: false,
        column: [
          {
            label: '会员',
            search: true,
            prop: 'member_name'
          },
          {
            label: '类型',
            type: 'radio',
            dicData: [
              {
                label: '充值',
                value: 1
              },
              {
                label: '扣减',
                value: 2
              },
              {
                label: '扣减-充值退款',
                value: 3
              }
            ],
            search: true,
            prop: 'type'
          },
          {
            label: '来源',
            type: 'radio',
            dicData: [
              {
                label: '活动兑换',
                value: 1
              },
              {
                label: '订单取消',
                value: 2
              },
              {
                label: '管理员更新',
                value: 3
              },
              {
                label: '签到',
                value: 4
              },
              {
                label: '上课使用',
                value: 5
              },
              {
                label: '赠送',
                value: 6
              },
              {
                label: '取消兑换退还',
                value: 8
              },
              {
                label: '活动签到',
                value: 9
              },
              {
                label: '充值退款',
                value: 10
              }
            ],
            prop: 'source_type',
            search: 'true'
          },
          {
            label: '数量',
            prop: 'number'
          },
          {
            label: '兑换比',
            prop: 'prop'
          },
          {
            label: '有效价值',
            prop: 'actual_value'
          },
          {
            label: '余额',
            prop: 'balance'
          },
          {
            label: '有效期',
            prop: 'expires_time',
            type: 'date',
            format: 'yyyy-MM-dd'
          },
          {
            label: '备注',
            prop: 'remark'
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '无效',
                value: -1
              },
              {
                label: '有效',
                value: 1
              }
            ],
            display: true,
            prop: 'status',
            search: 'true'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            type: 'daterange',
            searchRange: true,
            label: '创建日期',
            span: 8,
            hide: true,
            display: false,
            search: true,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'datetime'
          }
        ]
      },
      searchData: {
        datetime: []
      }
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 导出
    searchExcel(params) {
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      // 导出
      dataApi.getExport.r(params).then((res) => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href = res.data;
        });
      });
    },
    // 筛选
    searchChange(params, done) {
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.memberSearchFo.table = '';
      this.page.currentPage = 1;
      this.searchData = {
        datetime: []
      };
      this.getList();
    },

    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
