import http from '@/utils/http';

/**
 * 能量球充值日志
 */
export default {
  getList: {
    p: 'post,/manage/energytopup/getlist',
    r: (data) => http({ url: '/manage/energytopup/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/energytopup/add',
    r: (data) => http({ url: '/manage/energytopup/add', method: 'post', data })
  },
  del: {
    p: 'post,/manage/energytopup/delete',
    r: (data) => http({ url: '/manage/energytopup/delete', method: 'post', data })
  },
  getone: {
    p: 'post,/manage/energytopup/getone',
    r: (data) => http({ url: '/manage/energytopup/getone', method: 'post', data })
  },
  update: {
    p: 'post,/manage/energytopup/update',
    r: (data) => http({ url: '/manage/energytopup/update', method: 'post', data })
  },
  getExport: {
    p: 'post,/manage/energytopup/export',
    r: (data) => http({ url: '/manage/energytopup/export', method: 'post', data })
  }
  // 导出
};
