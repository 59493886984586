import http from '@/utils/http';

/**
 * 会员能量球日志
 */
export default {
  getList: {
    p: 'post,/member/energy/getlist',
    r: (data) => http({ url: '/member/energy/getlist', method: 'post', data })
  },
  getSelect: {
    p: 'post,/manage/energy/select',
    r: (data) => http({ url: '/manage/energy/select', method: 'post', data })
  },
  getExport: {
    p: 'post,/manage/energy/export',
    r: (data) => http({ url: '/manage/energy/export', method: 'post', data })
  }
};
