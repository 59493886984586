<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <!-- <template slot="searchMenu" slot-scope="{ row, size }">
        <el-button icon="el-icon-download" :size="size" @click="searchExcel(row)"
        >导出</el-button>
      </template> -->
       <template slot="member_nameSearch">
        <div style="height: 32px;width: 100%">
          <avue-form ref="form" :option="memberSearchOp" v-model="memberSearchFo" ></avue-form>
        </div>
      </template>
      <template  slot-scope="{ row }" slot="pay_amount">
        <div style="text-align: left">
          订单金额  &nbsp;<span style="color: #79bbff">{{ row.pay_amount }}</span> <br>
          优惠金额 - <span style="color: #f89898">{{ row.coupon_amount }}</span> <br>
          实付金额 = <span style="color: #95d475">{{ row.actual_payment }}</span>
        </div>
      </template>
      <template #serial_no="{row}">
          <el-link type="primary" :underline="false" @click="viwClick(row)">{{row.serial_no}}</el-link>
      </template>
   
      <template slot="searchMenu" slot-scope="{ row, size }">
        <el-button icon="el-icon-download" :size="size" @click="searchExcel(row)"
        >导出</el-button>
      </template>
      <template
        slot-scope="{type,size,row}"
        slot="menu"
      >
        <el-button
          icon="el-icon-edit"
          :size="size"
          @click="upEnergyExpires(row)"
          :type="type"
        >更新有效期</el-button>
      </template>
    </avue-crud>
    <el-drawer
        title="充值详情"
        :before-close="drawerInfoClose"
        :visible.sync="drawerInfoVisible"
        size="80%">
      <div class="details">
        <div class="details-view">
          <div class="details-view-item">
            <div class="details-view-item-label">流水号:</div>
            <div class="details-view-item-value">{{ info.serial_no }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">能量值:</div>
            <div class="details-view-item-value">{{ info.energy }}个</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">支付类型:</div>
            <div class="details-view-item-value">{{ info.pay_type_text }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">支付金额:</div>
            <div class="details-view-item-value">{{ info.pay_amount }}元</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">open_id:</div>
            <div class="details-view-item-value">{{ info.open_id }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">使用能量:</div>
            <div class="details-view-item-value">{{ info.use_energy }}个</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">兑换比:</div>
            <div class="details-view-item-value">{{ info.prop }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">状态:</div>
            <div class="details-view-item-value">{{ info.status_text }}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">创建时间:</div>
            <div class="details-view-item-value">{{ info.create_time }}</div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!--更新有效期-->
    <el-dialog
      title="更新充值有效期"
      size="500"
      :close-on-click-modal="false"
      :visible.sync="expiresDialogVisible"
      @close="dialogCloseExpires"
    >
      <avue-form
        ref="upExpiresForm"
        v-model="expiresDa"
        :option="expiresOp"
        @submit="submitExpires"
      >
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
