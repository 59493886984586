import './list.scss';
import dataApi from '@/api/member/recharge.js';
import member from '@/api/member/member';
/*
 *能量值日志
 * */
export default {
  name: 'member-recharge',
  components: {},
  data() {
    return {
      drawerInfoVisible: false,
      expiresDialogVisible: false,
      info: {},
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      memberSearchOp: {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            labelWidth: 0,
            label: '',
            prop: 'table',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '会员名称',
                  display: true,
                  prop: 'name',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  type: 'input',
                  label: '手机号',
                  display: true,
                  prop: 'mobile',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  label: '能量值',
                  prop: 'energy'
                },
                {
                  label: '赠送能量值',
                  prop: 'giving_energy'
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              return `${row.name}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              // 请求列表数据
              member.getList.r({ ...data, page: page.currentPage, pageSize: page.pageSize }).then((res) => {
                // 渲染数据
                callback({
                  total: res.data.total,
                  data: [...res.data.list]
                });
              });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          }
        ]
      },
      memberSearchFo: {},
      tableData: [],
      tbleOption: {
        ...this.$store.getters.tableConfig,
        total: 20,
        align: 'center',
        cellBtn: false,
        menu: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        cancelBtn: false,
        filterBtn: false,
        selection: false,
        column: [
          {
            label: '流水号',
            search: true,
            prop: 'serial_no'
          },
          {
            label: '会员名称',
            search: true,
            prop: 'member_name'
          },
          {
            label: '能量值',
            prop: 'energy'
          },
          {
            label: '订单金额',
            addDisplay: false,
            editDisplay: false,
            width: 130,
            prop: 'pay_amount'
          },
          {
            label: '支付方式',
            type: 'select',
            search: 'true',
            prop: 'pay_type',
            dicData: [
              {
                label: '线下付款',
                value: 'cash'
              },
              {
                label: '微信',
                value: 'weixin'
              },
              {
                label: '支付宝',
                value: 'alipay'
              }
            ],
            hide: true
          },
          {
            label: '支付方式',
            type: 'select',
            prop: 'pay_type_text'
            
          },
          {
            type: 'radio',
            label: '状态',
            display: true,
            prop: 'status',
            search: 'true',
            dicData: [
              {
                label: '已关闭',
                value: -1
              },
              {
                label: '待支付',
                value: 1
              },
              {
                label: '已支付待能量球完成充值',
                value: 2
              },
              {
                label: '已完成',
                value: 4
              },
              {
                label: '已退款',
                value: 5
              }
            ]
          },
          {
            type: 'radio',
            label: '有效期',
            display: true,
            prop: 'is_expires',
            search: 'true',
            dicData: [
              {
                label: '全部',
                value: 0
              },
              {
                label: '未到期',
                value: 1
              },
              {
                label: '已到期',
                value: 2
              }
            ]
          },
          {
            type: 'radio',
            label: '完结',
            display: true,
            prop: 'is_end',
            search: 'true',
            dicData: [
              {
                label: '全部',
                value: 0
              },
              {
                label: '未完结',
                value: 1
              },
              {
                label: '已完结',
                value: 2
              }
            ]
          },
          {
            label: '充值模板',
            prop: 'topup_tpl'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            type: 'radio',
            label: '日期类型',
            value: '',
            dicData: [
              {
                label: '创建时间',
                value: 'create'
              },
              {
                label: '完成时间',
                value: 'update'
              },
              {
                label: '支付时间',
                value: 'pay_time'
              }
            ],
            hide: true,
            display: false,
            search: 'true',
            prop: 'date_type',
            span: 6
          },
          {
            type: 'datetime',
            searchRange: true,
            label: '日期范围',
            span: 8,
            hide: true,
            display: false,
            search: 'true',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'datetime'
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      },
      searchData: {},
      expiresOp: {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            label: '流水号',
            prop: 'serial_no',
            disabled: true
          },
          {
            label: '数量',
            prop: 'energy',
            disabled: true
          },
          {
            label: '有效期',
            prop: 'expires_time',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'timestamp',
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now();
              }
            }
          }
        ]
      },
      expiresDa: []
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    drawerInfoClose() {
      this.drawerInfoVisible = false;
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    viwClick(row) {
      dataApi.getone.r({ id: row.id }).then((res) => {
        console.log(res);
        this.info = res.data;
        this.drawerInfoVisible = true;
      });
    },
    // 导出
    searchExcel(params) {
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      
      // 导出
      dataApi.getExport.r(params).then((res) => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href = res.data;
        });
      });
    },
    // 筛选
    searchChange(params, done) {
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },

    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    // 有效期
    upEnergyExpires(row) {
      this.expiresDialogVisible = true;
      if (!row.expires_time) {
        row.expires_time = Date.now();
      }
      this.expiresDa = row;
    },
    submitExpires(form, done) {
      form.source_id = form.serial_no;
      // form.member_id = this.memberSearchFo.table;
      member.up_energy_expires
        .r(form)
        .then(() => {
          this.expiresDialogVisible = false;
          this.$refs.upExpiresForm.resetForm();
          done(form);
          this.getList();
          this.$message.success('操作成功~');
        })
        .catch(() => {
          done(form);
        });
    },
    dialogCloseExpires() {
      this.expiresDialogVisible = false;
    }
  }
};
